import './ChangePasswordContent.less'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby-plugin-react-intl'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  AccountType,
  CustomConsent,
  changePassword,
  fetchCustomConsents as fetchCustomConsentsRequest,
  fetchTenantData,
  verifyChangePasswordToken
} from '../../../requests'
import { routes } from '../../../routes'
import { getLanguage } from '../../../utils'
import { DatacMessage, DatacSubtitle, DatacTitle } from '../../common'
import { ChangePasswordForm, ChangePasswordFormData } from '../ChangePasswordForm'
import { PasswordRejectionError } from '../validatePassword'

interface Props {
  accountType: AccountType
  setHasCustomTranslations?: (hasCustomTranslations: boolean) => void
}
export const ChangePasswordContent: React.FC<Props> = ({ accountType, setHasCustomTranslations }) => {
  const location = useLocation()
  const intlChangePassword = useScopedIntl('auth.change_password')
  const intl = useScopedIntl('')
  const [passwordRejectionError, setPasswordRejectionError] = useState<PasswordRejectionError>(null)
  const [tokenVerified, setTokenVerified] = useState(false)
  const [isOldPasswordNeeded, setIsOldPasswordNeeded] = useState(false)
  const [isConsentNeeded, setIsConsentNeeded] = useState(false)
  const [consents, setConsents] = useState<CustomConsent[]>(null)
  const [isFetchingConsents, setIsFetchingConsents] = useState(true)
  const [isWrongCredentials, setIsWrongCredentials] = useState(false)
  const [isLastAttempt, setIsLastAttempt] = useState(false)
  const { token } = queryString.parse(location.search)
  const [minPasswordLength, setMinPasswordLength] = useState<number>(null)

  const fetchCustomConsents = () => {
    fetchCustomConsentsRequest(
      { language: getLanguage() },
      {
        onSuccess: consents => {
          setConsents(consents)
          setIsFetchingConsents(false)
          if (setHasCustomTranslations) {
            setHasCustomTranslations(true)
          }
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  useEffect(() => {
    verifyChangePasswordToken(
      { token: token as string, accountType },
      {
        onSuccess: (isOldPasswordNeeded, isConsentNeeded) => {
          setTokenVerified(true)
          setIsOldPasswordNeeded(isOldPasswordNeeded)
          setIsConsentNeeded(isConsentNeeded)
          if (isConsentNeeded) {
            fetchCustomConsents()
          } else {
            setIsFetchingConsents(false)
          }
        },
        onRequestError: () => {
          navigate(routes.notFound(accountType))
        }
      }
    )
    fetchTenantData({
      onSuccess: ({ minPasswordLength }) => setMinPasswordLength(minPasswordLength),
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }, [])

  const onFormSubmit = (data: ChangePasswordFormData) =>
    new Promise<void>(resolve =>
      changePassword(
        { password: data.password, token: token as string, currentPassword: data.currentPassword },
        {
          onSuccess: () => {
            setPasswordRejectionError(null)
            navigate(routes.changePasswordSuccess(accountType))
            resolve()
          },
          onRequestError: code => {
            DatacMessage.genericError(intl, code)
            resolve()
          },
          onPasswordTheSameAsCurrent: () => {
            setPasswordRejectionError(PasswordRejectionError.TheSameAsCurrent)
            resolve()
          },
          onPasswordTooCommon: () => {
            setPasswordRejectionError(PasswordRejectionError.TooCommon)
            resolve()
          },
          onPasswordTooSimilar: () => {
            setPasswordRejectionError(PasswordRejectionError.TooSimilar)
            resolve()
          },
          onInvalidCredentials: (attemptsLeft: number) => {
            setIsWrongCredentials(true)
            setIsLastAttempt(attemptsLeft === 1)
            resolve()
          }
        }
      )
    )
  const onFormValuesChange = () => {
    if (passwordRejectionError) setPasswordRejectionError(null)
    if (isWrongCredentials) setIsWrongCredentials(false)
    if (isLastAttempt) setIsLastAttempt(false)
  }

  return tokenVerified && minPasswordLength && !isFetchingConsents ? (
    <div className="change-password-content">
      <DatacTitle type="h1" className="change-password-content__title">
        {intlChangePassword('title')}
      </DatacTitle>
      <DatacSubtitle type="h2" className="change-password-content__subtitle">
        {isOldPasswordNeeded ? intlChangePassword('outdated_password') : intlChangePassword('subtitle')}
      </DatacSubtitle>
      <ChangePasswordForm
        onSubmit={onFormSubmit}
        onChange={onFormValuesChange}
        passwordRejectionError={passwordRejectionError}
        isWrongCredentials={isWrongCredentials}
        isLastAttempt={isLastAttempt}
        isOldPasswordNeeded={isOldPasswordNeeded}
        minPasswordLength={minPasswordLength}
        consents={consents}
        isConsentNeeded={isConsentNeeded}
      />
    </div>
  ) : null
}
